<template>
    <div class="container-fluid animatedParent animateOnce my-3">
        <div class="tab-content">
            <div class="tab-pane animated fadeInUpShort show active go" id="v-pills-all" role="tabpanel"
                aria-labelledby="v-pills-all-tab">
                <div class="row my-3">
                    <div class="col-md-12">
                        <div class="card r-0 shadow">
                            <div class="card-header">


                                <div class="row my-3">
                                    <div class="col-md-6">
                                        <!-- 搜索 start -->
                                        <div class="input-group w-100 m-3 form-inline">
                                            <div class="input-group-btn">
                                                <input v-model="query" class="form-control p-2" placeholder="搜索"
                                                    type="text">
                                                <select v-model="category_id" class="form-control ml-2" style="height:42px;">
                                                    <option value="0">所有分类</option>
                                                    <option :value="item.id" v-for="(item,index) in categorylist"
                                                        v-bind:key="index">{{item.category_name}}</option>
                                                </select>
                                                <!-- <select v-model="enabled" class="form-control" style="height:42px;">
                                                <option value="-2">所有状态</option>
                                                <option value="1">正常使用</option>
                                                <option value="0">沽清</option>
                                                <option value="-1">已下架</option>
                                            </select> -->
                                                <button @click="search" type="button" class="btn btn-secondary ml-2"><i
                                                        class="icon-search"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <!-- 搜索 end -->
                                    </div>
                                    <div class="col-md-6 ">
                                        <div class="btn-groupx float-right" role="group" aria-label="Basic example">

                                            <!-- <button @click="add" type="button" class="btn btn-secondary">创建商品</button> -->

                                            <button @click="quickAdd" type="button"
                                                class="btn btn-secondary ml-2">添加商品<!-- 简易录菜 --></button>

                                            <!-- <button @click="import2" type="button" class="btn btn-secondary ml-2">导入</button> -->

                                            <!-- <button type="button" class="btn btn-secondary">导出</button> -->
                                            <!-- <div class="text-center">
                                                
                                            <img data-toggle="modal" data-target="#weappModal" src="../static/admin-llm.jpg"  style="width: 80px; height: 80px;"/>
                                            <span style="display: block;margin-top: 5px;font-size: 10px;">微信扫一扫管理菜品（点击放大）</span>
                                            
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="table-responsive">
                                <div>
                                    <table class="table table-striped table-hover r-0 mb-0">
                                        <thead>
                                            <tr class="no-b">
                                                <!-- <th style="width: 30px">
                                                    <div class="custom-control custom-checkbox">
                                                        <input v-model="check_all" @click.stop="checkAll" type="checkbox"  class="custom-control-input" id="checkedAll"><label class="custom-control-label" for="checkedAll"></label>
                                                    </div>
                                                </th> -->
                                                <th>商品</th>
                                                <th></th>
                                                <th>
                                                    <div class="d-none d-lg-block">分类</div>
                                                </th>

                                                <th>
                                                    <div class="d-none d-lg-block">单价</div>
                                                </th>

                                               <!-- <th>
                                                    <div class="d-none d-lg-block">打包餐盒</div>
                                                </th>

                                                <th>
                                                    <div class="d-none d-lg-block">特性</div>
                                                </th>

                                                <th>
                                                    <div class="d-none d-lg-block">沽清状态</div>
                                                </th> -->

                                                <th></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr v-for="(item,index) in productlist"
                                                :class="{'blue lighten-5':selectedIndex == index}" v-bind:key="index">
                                                <!-- <td>
                                                    <label class="custom-control custom-checkbox">
                                                        <input v-model="checkedIds" :value="item.id" :id="index" :disabled="item.enabled == -1" class="custom-control-input" type="checkbox"><label class="custom-control-label" :for="index"></label>
                                                    </label>
                                                </td> -->
                                                <td class="table-img">
                                                    <img :src='item.img_url?ossUrl + item.id + ".jpg?x-oss-process=style/100x100&"+timestamp:"/static/no-img2.png"'
                                                        alt="" :onerror="defaultImg">
                                                </td>
                                                <td>
                                                    <h6><span class="font-weight-bold">{{item.product_name}}</span> <i
                                                            class="icon-restaurant" v-if="item.product_type == 1"></i>
                                                    </h6>
                                                    <small class="text-muted">{{item.description}}</small><br>
                                                    <span class="badge badge-pill badge-info">{{item.tag}}</span>
                                                </td>
                                                <td>
                                                    <div class="d-none d-lg-block">{{item.category_name}}</div>
                                                </td>
                                                <td>
                                                    <div class="d-none d-lg-block">￥{{item.price}}/{{item.unit}}</div>
                                                </td>
                                               <!-- <td>
                                                    <div class="d-none d-lg-block">
                                                        <div v-if="item.lunch_box_price>0">
                                                            ￥{{item.lunch_box_price}}*{{item.lunch_box_qty}}</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="d-none d-lg-block">
                                                        <span v-if="item.attachs.length>0"
                                                            class="text-light-blue">有加料</span>
                                                        <span v-if="item.tastes.length>0"
                                                            class="text-light-blue">口味可选</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="d-none d-lg-block">
                                                        <span
                                                            :class="[ 'badge badge-pill',{'' : item.clear_flag==0, 'badge-danger':item.clear_flag==1}]">{{item.clear_flag==1?'已沽清':'可售'}}</span>
                                                    </div>
                                                </td> -->

                                                <td>
                                                    <button @click.stop="edit(index)" type="button"
                                                        class="btn btn-outline-primary btn-xs"> 编辑 </button>

                                                    <!-- <button @click.stop="clearReady(index)" type="button"
                                                        :class="[ 'btn btn-xs  ml-2',{'btn-outline-danger' : item.clear_flag==0, 'btn-outline-primary':item.clear_flag==1}]">
                                                        {{item.clear_flag==0?'沽清':'撤消沽清'}} </button> -->
                                                    <!-- <button @click.stop="replaceReady(index)" type="button" :class="[ 'btn btn-xs ',{'btn-outline-primary' : item.active=='ON', 'btn-outline-success':item.active=='OFF'}]"> {{item.active=='OFF'?'上架':'下架'}} </button> -->

                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info" id="example2_info" role="status" aria-live="polite">
                            显示 {{show_from}} ~ {{show_to}} 条 / 共 {{count}} 条
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-7">

                        <Pagination :total="total" :current-page='current' @pagechange="pagechange"></Pagination>

                    </div>
                </div>

            </div>
        </div>

        <div class="modal fade" id="weappModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">商户端小程序</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">

                        <div class="m-3 text-center">
                            <img src="/static/admin-llm.jpg" />
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">关闭</button>
                    </div>
                </div>
            </div>
        </div>


        <!-- 沽清modal -->
        <div class="modal fade" id="cModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{product.clear_flag==0?'沽清':'撤消沽清'}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">

                        <div class="m-3">
                            <div class="form-group">

                                <div class="d-flex">
                                    <div class="mr-3 mb-2 mt-1">
                                        <figure class="avatar avatar-xl">
                                            <img :src='product.img_url?ossUrl + product.id + ".jpg?x-oss-process=style/100x100&"+timestamp:"/static/no-img2.png"'
                                                alt="">
                                        </figure>
                                    </div>
                                    <div>
                                        <h6><span class="font-weight-bold">{{product.product_name}}</span> <i
                                                class="icon-restaurant" v-if="product.product_type == 1"></i></h6>
                                        <small class="text-muted">{{product.description}}</small><br>
                                        <span class="badge badge-pill badge-info">{{product.tag}}</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
                        <button @click.stop="doClear" :disabled="disabled" type="button" class="btn btn-primary"> 确定
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- //沽清 modal -->

        <!-- 批量操作modal -->
        <div class="modal fade" id="bcModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">批量沽清</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">

                        <div class="m-3">
                            <div class="form-group">
                                <label>沽清范围</label>
                                <br>
                                <div class="mt-1">
                                    <input v-model="clear_range" id="rr_1" type="radio" name="clear_range" value="1"
                                        class="">
                                    按选中(共{{checkedIds.length}}条)，
                                    <input v-model="clear_range" id="rr_2" type="radio" name="clear_range" value="2"
                                        class="">
                                    按分类
                                    <!-- <input v-model="clear_range" id="rr_3" type="radio" name="clear_range" value="3" class="">
                            全部商品 -->
                                </div>
                            </div>

                            <div class="form-group" v-show="clear_range == 2">
                                <label>商品分类</label>
                                <div style="display: block;">
                                    <select v-model="cat_id" class="form-control recharge-group-id"
                                        style="height:42px;">
                                        <option value="0">请选择分类</option>
                                        <option v-bind:key="index" :value="item.id"
                                            v-for="(item,index) in categorylist">{{item.category_name}}</option>
                                    </select>
                                </div>
                            </div>

                            <!-- <div class="form-group">
                    <label>备注</label>
                    <input v-model="rechargeorder.remark" class="form-control form-control-lg" placeholder="输入备注" type="text">
                   </div> -->
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
                        <button @click.stop="doBatClear" :disabled="disabled" type="button" class="btn btn-primary"> 确定
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- // 批量操作 modal -->

    </div>
</template>

<script>
    import Pagination from '../components/my-page.vue'

    export default  {
        data: function() {
            return {
                title: '商品列表',

                token: '',
                outlet_id: 0,
                outlet_name: '',
                user_name: '',

                setting: {},
                outletlist: [],

                productlist: [],
                query: '',
                category_id: 0,
                enabled: -2,

                current_page: 1,
                page_size: 50,
                count: 0,
                total_page: 0,
                show_from: 0,
                show_to: 0,

                total: 150, // 记录总条数
                display: 10, // 每页显示条数
                current: 1, // 当前的页数

                categorylist: [],

                product: {}, //选中的会员对象，用于充值，换卡
                selectedIndex: -1,
                disabled: false,

                cat_id: 0,
                clear_range: 1, //充值范围

                checkedIds: [],
                check_all: false,

                ossUrl: '',
                timestamp: new Date().getTime(),
				
				defaultImg: 'this.src="' + require('../assets/img/no-img.png') + '"' //默认图地址
            }
        },
        props: {

        },

        components: {
            Pagination
        },

        created: function() {
            console.log('--- created --- ')
            let self = this;

            //1.检查登录
            let token = localStorage.getItem('TOKEN');
            if (!token) {
            	return;
            }
            self.token = token;

            //2.取得店铺信息
            let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
            console.log(lsLoginData.logined_outlet_name)
            // self.outlet_id = lsLoginData.logined_outlet_id;
            console.info('--- this.$store.state ->', this.$store.state)
            self.outlet_id = this.$store.state.current_outlet_id || lsLoginData.logined_outlet_id;
            self.outlet_name = lsLoginData.logined_outlet_name;
            self.user_name = lsLoginData.user_account;

            self.setting = JSON.parse(localStorage.getItem('SETTING'));

            self.outletlist = JSON.parse(localStorage.getItem('OUTLETLIST'));
            console.log('--- outlet list ->', self.outletlist)

            self.ossUrl = this.GLOBAL.baseImgURI;
        },
        mounted: function() {
            console.log('--- mounted --- ')
            let self = this;

            //1.检查登录
            // let token = localStorage.getItem('TOKEN');
            // if (!self.token) {
            // 	console.log('--- mounted jump  --- ')
            // 	location.href = 'index.html';
            // 	return;
            // }

            self.init(() => {
                self.initData();
            });
        },
        methods: {
            init(callback) {
                let self = this;

                //取得分类列表
                this.axios.get(this.GLOBAL.baseURI + 'getCategoryList', {
                        params: {
                            token: self.token,
                            outlet_id: self.outlet_id,
                            // name: '',
                        }
                    })
                    .then(function(response) {
                        console.log(response);
                        return response.data;
                    })
                    .catch(function(error) {
                        console.log(error);
                    })
                    .then(function(data) {
                        // always executed
                        console.log(data.data);
                        if (data.code == 200) {
                            self.categorylist = data.data;
                            // __LIST = data.data;

                            if (typeof callback == 'function') {
                                callback()
                            }
                        } else if (data.code == 101) {
                            //登录超时
                            self.logoff();
                        } else {
                            alert(data.message)
                        }
                    });
                //END

            },
            initData() {
                let self = this;

                //取得商品列表
                this.axios.get(this.GLOBAL.baseURI + 'getProducts', {
                        params: {
                            token: self.token,
                            outlet_id: self.outlet_id,
                            query: self.query,
                            category_id: self.category_id,
                            enabled: self.enabled,
                            current_page: self.current_page,
                            page_size: self.page_size,
                        }
                    })
                    .then(function(response) {
                        console.log(response);
                        return response.data;
                    })
                    .catch(function(error) {
                        console.log(error);
                    })
                    .then(function(data) {
                        // always executed
                        console.log(data.data);
                        if (data.code == 200) {
                            let products = [];
                            for (let i = 0; i < data.data.products.length; i++) {
                                let product = data.data.products[i];
                                // product.img_url = product.img_url==undefined?'assets/img/dummy/u6.png':product.img_url
                                // product.img_url = 'assets/img/dummy/u6.png';
                                products.push(product);
                            }

                            self.productlist = products;
                            self.current_page = data.data.page_num;
                            self.page_size = data.data.page_size;
                            self.count = data.data.total;
                            self.total_page = Math.ceil(self.count / self.page_size);
                            console.log('--- self.total_page ->', self.total_page)

                            self.show_from = self.page_size * (self.current_page - 1) + 1;
                            self.show_to = self.page_size * self.current_page <= self.count ? self.page_size * self
                                .current_page : self.count;

                            self.total = self.count; // 记录总条数
                            self.display = self.page_size; // 每页显示条数
                            self.current = self.current_page; // 当前的页数
                        } else if (data.code == 101) {
                            //登录超时
                            self.logoff();
                        } else {
                            alert(data.message)
                        }
                    });
                //END

            },

            pagechange: function(currentPage) {
                console.log(currentPage);
                // ajax请求, 向后台发送 currentPage, 来获取对应的数据
                this.current_page = currentPage;
                this.initData();
            },

            edit(index) {
                console.log(index);
                let self = this;

                let product = self.productlist[index];

                let params = {
                    outlet_id: self.outlet_id,
                    id: product.id,
                    barcode: product.barcode,
                    product_name: product.product_name
                }
                //this.$router.push({ name: 'editproduct', params: params})//post 提交

                let query = {
                    no: product.barcode,
                    nonce_str: product.nonce_str
                }
                this.$router.push({
                    name: 'editproduct',
                    params: params,
                    query: query,
                    meta: {
                        title: '编辑商品',
                        isShow: true,
                        readonly: true
                    }
                }) //post get 双提交
            },


            add() {
                var self = this;

                this.$router.push({
                    name: 'addproduct',
                    params: {
                        outlet_id: self.outlet_id
                    }
                }) //【FANDY】 视为 POST 提交
            },

            quickAdd() {
                var self = this;

                this.$router.push({
                    path: 'editliteproduct',
                    params: {
                        outlet_id: self.outlet_id
                    },
                })
            },

            checkAll() {
                let self = this;
                self.check_all = !self.check_all;
                console.log('--- check all ---', self.check_all)

                if (self.check_all) {
                    for (var i = 0; i < self.productlist.length; i++) {
                        if (self.productlist[i].enabled == -1) continue;
                        self.checkedIds.push(self.productlist[i].id);
                    }
                } else {
                    self.checkedIds = [];
                }
            },

            search() {
                let self = this;

                self.current_page = 1;
                self.initData();
            },

            onOutletChangeListener(e) {
                let self = this;
                console.log('--- outlet ID->', e)
                self.outlet_id = e;

                self.init(() => {
                    self.initData();
                    self.category_id = 0;
                });
            },

            //批量操作
            batClearReady() {
                let self = this;

                // self.clear_range = 1;

                $('#bcModal').modal('show');

            },

            doBatClear() {

            },

            clearReady(index) {
                console.log(index);
                let self = this;

                self.selectedIndex = index;
                self.product = self.productlist[index];
                console.log(index, self.product);

                $('#cModal').modal('show');
            },

            doClear() {
                let self = this;

                var index = self.selectedIndex;
                let state = self.product.clear_flag == 0 ? 1 : 0;

                // 组装数据
                let postData = {
                    token: self.token,
                    outlet_id: self.outlet_id,
                    product_id: self.product.id,
                    act: 'clear',
                    state: state,

                }

                let postData2 = new FormData();
                for (var key in postData) {
                    console.log(key + ':' + postData[key]);
                    postData2.append(key, postData[key]);
                }


                //调用接口
                this.axios.post(this.GLOBAL.baseURI + 'updateProductState',
                        postData2
                    )
                    .then(function(response) {
                        console.log(response);
                        return response.data;
                    })
                    .catch(function(error) {
                        console.log(error);
                    })
                    .then(function(data) {
                        // always executed
                        console.log(data.data);

                        self.disabled = false;

                        if (data.code == 200) {
                            // alert('操作成功')
							self.$toasted.success('操作成功', {
								position: 'top-center',
							}).goAway(2000)
                            $('#cModal').modal('hide');

                            //UI刷新
                            self.productlist[index]["clear_flag"] = state;
                        } else if (data.code == 101) {
                            //登录超时
							 $('#cModal').modal('hide');
                            self.logoff();
                        } else {
                            alert(data.message)
                        }
                    });
                //END
            },


            import2() {
                var self = this;

                this.$router.push({
                    name: 'importproducts',
                    params: {
                        outlet_id: self.outlet_id
                    }
                })

            },
        },

    }
</script>

<style>
    /* @import '../css/iconfont.css'; */
</style>
